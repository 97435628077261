body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: 'Sequel100Black-95';
  src: url('./fonts/Sequel100Black-95.woff2') format('woff2'),
      url('./fonts/Sequel100Black-95.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

.main-logo {
  
}

a {
  color: #000;
}

select {
  border: 1px solid #888;
  border-radius: 3px;
  background-color: #fff;
}

.center {
  text-align: center;
}

.link {
  cursor: pointer;
  text-decoration: underline;
}

.hidden {
  display: none !important;
}

.hidden-link {
  text-decoration: none;
}

.strong {
  font-weight: 700;
}

.float-right {
  float:  right;
}

.align-right {
  text-align: right;
}

.clickable {
  cursor: pointer;
}

.clickable.label {
  padding-left: 5px;
  padding-right: 3px;
  margin-right: -3px;
  border-radius: 5px;
}

.clickable.label:hover {
  background-color: #dadada;
}

.block {
  display: block !important;
}

.table {
  display:table;
}

.tr {
  display:table-row;
}

.td {
  display:table-cell;
  border: 1px solid #aaa;
}

.red {
  color: #dd3e31;
  font-weight: 600;
}

.white-bg {
  background-color: #fff !important;
}

.th {
  display: table-cell;
  text-align: center;
  font-weight: bold;
  border: 1px solid #aaa;
}

.long-tr {
  background-color: #eee;
  width: 100%;
  border-radius: 0px 0px 10px 10px;
}

.long-td {
  padding: 15px 30px;
}

.long-tdb {
  padding: 15px 30px;
  border-radius: 0px 0px 10px 10px;
  background-color: #ffffff;
}

.section {
  margin-top: 60px;
}

.inline-block {
  display: inline-block;
}

.margin-left {
  margin-left: 10px;
}

.small-bottom-margin {
  margin-bottom: 10px !important;
}

.bottom-margin {
  margin-bottom: 30px !important;
}

.no-bottom-margin {
  margin-bottom: 0 !important;
}

.no-top-margin {
  margin-top: 0 !important;
}

.error {
  background-color: lightpink;
  border: 2px solid #ff6a6a;
  border-radius: 6px;
  padding: 5px 10px;
  width: fit-content;
  display: inline-block;
  float: right;
  margin-bottom: 8px;
}

.padding-bottom {
  padding-bottom: 20px;
}

.margin-right {
  margin-right: 10px;
}

.margin-right-20 {
  margin-right: 20px;
}

h2.page-title {
  margin-bottom: 0;
}

h3.import-title {
  margin-top: 60px;
}

h4.organisation-name {
  margin-top: 5px;
  font-weight: 400;
  color: #555;
  margin-bottom: 40px;
}

.relative {
  position: relative;
}

.inline {
  display: inline;
}

/* -- header -- */

header {

}

.pcs-logo {
  width: 69px;
  vertical-align: middle;
  background: #fff;
  border-radius: 50%;
  padding: 3px;

  text-decoration: none;
}

.dr-logo {
  width: 69px;
  vertical-align: middle;
  background: #fff;
  border-bottom-right-radius: 20%;
  padding: 3px;

  text-decoration: none;
}

.cc-logo {
  width: 59px;
  vertical-align: middle;
  background: #fff;
  border-top-left-radius: 50%;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 9px;

  text-decoration: none;
  border: 2px solid #777;
  margin: 1px;
}

.logo-link {
  position: absolute;
}

.logo-link::after {
  height: 0px;
}

header nav {
  display: inline-block;
}

header nav.right-nav {
  float: right;
}

header nav.right-nav ul {
  margin: 0;
}

header nav li {
  display: inline-block;
  text-decoration: none;

  border: 1px solid #aaa;
  border-top: 0px;
  border-bottom: 0px;
  border-left: 0px;

  font-size: 14px;
}

header nav li:last-child {
  border: 0;
}

header nav a {
  color: #000;
  text-decoration: none;
  font-weight: 600;
  display: block;
  vertical-align: initial;
  padding: 7px 20px 7px;
}

header nav a::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: transparent;
  position: absolute;
  top: 1px;
  left: 0;
  position: relative;
  outline: none;
}

header nav a:hover {
  color: #000;
  background-color: var(--base-color, #20c7d4);
}

header nav a:hover::after {
  background-color: #000;
}

header nav a svg {
  margin-right: 4px;
}

.left-nav ul {
  margin: 0;
  margin-left: 30px;
}

.bottom-header {
  background-color: var(--base-color, #20c7d4);
  padding: 6px 19px 9px 0px;
  margin-left: 23px;
}

.dr-header-text {
  font-size: 20px;
  font-weight: 700;
  text-align: right;
  color: #fff;
}

.signed-out-top-header {
  text-align: right;
}

.dropdown:hover .dropdown-content {
  display: block;
  min-width: 165px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  z-index: 1;
}

.notification-count {
  background-color: var(--base-color, #20c7d4);
  border-radius: 20px;
  text-align: center;
  color: white;
  display: inline-block;
  min-width: 13px;
  line-height: 11px;
  max-height: 11px;
  border: 2px solid white;
  box-shadow: 1px 1px 3px black;
  margin-bottom: -3px;
  padding: 4px 4px 4px 4px !important;
}

.notification-indicator {
  background-color: var(--base-color, #20c7d4);
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  width: 10px;
  margin-bottom: 5px;
  margin-right: -8px;
}

.notification-indicator.red {
  background-color: #dd3e31;
}

.notification-seperate-link {
  display: grid;
  grid-template-columns: auto auto;
}

.notification-seperate-link .notification-count {
  margin-top: 5px;
  margin-left: -15px;
  margin-right: 10px;
  margin-bottom: 5px;
}

.notification-seperate-link:hover {
  background-color: var(--base-color, #20c7d4);
}

.unread-replies {
  background-color: #dd3e31 !important;
}

/* -- login page -- */

.center-container {
  display: block;
  text-align: center;
}

.login-box {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  text-align: left;
}

.welcome-to {
  margin-bottom: 1px;
  margin-left: 71px;
}

.main-logo {
    width: 750px;
}

.login-form {
  margin: 30px auto 0;
  border: 5px solid #eee;
  border-radius: 15px;
  padding: 20px;
  max-width: 400px;
}

.login-form input {
  width: 98%;
  height: 30px;
  font-size: 22px;
  padding-left: 6px;
  margin-bottom: 10px;
  border: 1px solid #aaa;
  border-radius: 5px;
}

.login-form p {
  margin: 0 0 30px;
}

.login-form input[type="submit"] {
  width: 100.2%;
  height: 50px;
}

.login-form input[type="submit"]:hover {
  cursor: pointer;
  background-color: var(--base-color, #20c7d4);
  color: #000;
  font-weight: 600;
  border: 2px solid #000;
}

.google-login-container {
  width: min-content;
  margin: 20px auto;
}

.google-login-error {
  margin: auto;
  width: 400px;
  text-align: center;
}

p.italics {
  text-align: right;
  margin-bottom: 0;
  font-style: italic;
  font-size: 11px;
}

.errors {
  margin-top: 20px;
  background-color: lightpink;
  border: 3px solid #ff6a6a;
  border-radius: 15px;
  padding: 15px 0 0 15px;
}


.logout-message {
  text-align: center;
  margin-top: 60px;
}

.forgotten-password {
  max-width: 570px;
}

.forgotten-password p {
  text-align: center;
}

.forgotten-password .success {
  margin-bottom: 0;
}

@keyframes blink1 {
  0% { opacity: 1; }
  33% { opacity: 0.5; }
  66% { opacity: 0.2; }
  100% { opacity: 0; }
}

@keyframes blink2 {
  0% { opacity: 0; }
  33% { opacity: 1; }
  66% { opacity: 0.5; }
  100% { opacity: 0.2; }
}

@keyframes blink3 {
  0% { opacity: 0.2; }
  33% { opacity: 0; }
  66% { opacity: 1; }
  99% { opacity: 0.5; }
}

.blink1 {
  animation: blink1 0.5s infinite;
}

.blink2 {
  animation: blink2 0.5s infinite;
}

.blink3 {
  animation: blink3 0.5s infinite;
}

/* -- main page -- */

.main-page {
  margin: 30px;
}

.widgets {
  clear: both;
}

.widget {
  border: 5px solid #eee;
  border-radius: 15px;
  padding: 10px;
  margin: 30px 10px;
  display: inline-block;
  vertical-align: top;
}

.widget h3, .widget-title {
  font-weight: bold;
  font-size: 18px;
  margin-top: 0px;
}

.main-page h2 {
  display: inline-block;
}

.main-page .edit-button {
  display: inline-block;
  margin-left: 10px;

  margin-top: 15px;
  margin-bottom: 14px;
  float: right;
  text-align: center;

  background-color: #efefef;
  border: 1px solid #000;
  border-radius: 10px;
  box-shadow: 1px 1px 1px #000;
  padding: 10px;
  cursor: pointer;
}

.main-page.print {
  display: inline;
}

.main-page .edit-button.print {
  margin-top: 0px;
}

.main-page .edit-button:hover {
  background-color: #bbb;
  box-shadow: 3px 3px 1px #000;

}

.main-page .edit-button h4 {
  margin: 0;
  display: inline-block;
  font-weight: 400;
  margin-bottom: 3px;
}

.edit-icon {
  padding-left: 15px;
  vertical-align: bottom;
}

.main-page .no-float {
  float: none;
}

.edit-button.download-csv {
  position: absolute;
  top: -190px;
  right: 10px;
}

.edit-button.download-csv h4 {
  margin-left: 10px;
}

.edit-button.download-csv svg {
  font-size: 30px;
  vertical-align: bottom;
}

.edit-button.download-csv.relative-csv {
  position: relative;
  float: none;
  margin-top: 0;
  top: 0;
  right: 0;
}

.list-of-users {
  margin-top: 50px;
  margin-bottom: 30px;
}

.support-user {
  margin-bottom: 70px;
}

.right-container {
  text-align: right;
  margin-bottom: 30px;
}

.cool-button {
  display: inline-block;
  background-color: #efefef;
  border: 1px solid #000;
  border-radius: 10px;
  box-shadow: 1px 1px 1px #000;
  padding: 10px;
  cursor: pointer;
}

.cool-button:hover {
  background-color: #bbb;
  box-shadow: 3px 3px 1px #000;

}

.cool-button h4 {
  margin: 0;
  display: inline-block;
  font-weight: 400;
  vertical-align: middle;
}

.cool-button svg {
  padding-left: 12px;
  font-size: 45px;
  vertical-align: middle;
  color: #c00000;
}

/* -- settings pages -- */

.settings input {
  width: 45%;
  height: 30px;
  font-size: 22px;
  padding-left: 6px;
  margin-bottom: 10px;
  border: 1px solid #aaa;
  border-radius: 5px;
  display: inline-block;
}

.settings select {
  width: 45%;
  height: 30px;
  font-size: 22px;
  padding-left: 6px;
  margin-bottom: 10px;
  border: 1px solid #aaa;
  border-radius: 5px;
  display: inline-block;
}

.settings p {
  margin: 0 0 10px;
}

.settings input[type="submit"] {
  width: auto;
  height: 34px;
  display: inline-block;
  padding: 0 30px;
  margin-left: 10px;
  border: 2px solid #666;
  background-color: #ccc;
}

.settings input[type="submit"]:not([disabled]):hover {
  cursor: pointer;
  background-color: var(--base-color, #20c7d4);
  color: #000;
  font-weight: 600;
  border: 2px solid #000;
}

.big-settings {
  margin: 30px auto 0;
  width: 80%;
  background-color: #eee;
  padding: 30px;
  border-radius: 7px;
}

.big-settings label {
  margin-bottom: 10px;
  display: inline-block;
}

.short-input {
  width: 100px !important;
}

.big-settings input {
  width: 100%;
  margin-bottom: 30px;
}

.big-settings input[type="time"] {
  width: 47%;
  margin-bottom: 30px;
  float: right;
}

.big-settings input[type="date"] {
  width: 47%;
  margin-bottom: 30px;
}

.big-settings .center-cell input[type="time"] {
  float: none;
  margin-bottom: 15px;
  margin-top: 15px;
}

.big-settings .center-cell input[type="date"] {
  width: 180px;
  margin: 0;
}

.big-settings select {
  width: 100%;
  margin-bottom: 40px;
}

.big-settings textarea {
  width: 97%;
  height: 150px;
  font-size: 22px;
  padding-left: 6px;
  margin-bottom: 10px;
  border: 1px solid rgb(170, 170, 170);
  border-radius: 5px;
  margin-top: 0px;
  font-family: inherit;
  outline: none;
}

.big-settings input[type="checkbox"] {
  cursor: pointer;
  width: 34px;
  height: 34px;
  margin: 0 10px 30px 0;
}

.big-settings input[type="radio"] {
  cursor: pointer;
}

.big-settings .checkbox-label {
  cursor: pointer;
  margin-top: 7px;
  vertical-align: top;
}

.big-settings input:disabled {
  cursor: default;
}

.big-settings input:disabled + .checkbox-label {
  cursor: default;
}


.segment-settings {
  margin: 0px;
  width: 100%;
  background-color: #fff;
  padding: 0px;
}

.segment {
  margin: 30px auto 0;
  width: 80%;
  background-color: #eee;
  padding: 30px;
  border-radius: 7px;
}

.inline-text {
  display: inline-block;
}

.medium-settings {
  margin: 30px auto 0;
  width: fit-content;
  background-color: #eee;
  padding: 30px;
  border-radius: 7px;
}

.big-settings .inline-image {
  margin-bottom: 30px;
}

.big-settings .inline-image img {
  width: 500px;
  border-radius: 15px;
}

.big-settings .inline-image button {
  display: block;
}

.big-top-padding {
  margin-top: 70px;
}

.top-padding {
  margin-top: 40px;
}

.small-top-padding {
  margin-top: 10px;
}

.textbox-and-checkbox .checkbox {
  text-align: right;
}

.key-value-pairs {
  display: grid;
  grid-template-columns: 2fr 2fr 0fr;
  grid-column-gap: 20px;
}

.key-value-pairs input {
  margin-bottom: 5px;
}

.key-value-pairs svg {
  margin-top: 8px;
}

.key-value-pairs svg:hover {
  color: red;
  cursor: pointer;
}

.key-value-pairs:first-child svg {
  color: #eee;
}

button.right-button {
  margin: 10px 0 33px auto;
  display: block;
}

.checkbox-table {
  width: 100%;
  margin: 15px 0 25px;
  border-spacing: 0px;
  border-radius: 10px;

  border-collapse: separate;
  overflow: hidden;
}

.checkbox-table .center-cell {
  text-align: center;
  padding: 0px;
}

.checkbox-table input[type="checkbox"] {
  margin: 10px 0;
}

.checkbox-table th {
  border: 1px solid #888;
}

.checkbox-table td {
  border: 1px solid #aaa;
  padding-left: 15px;
}

.checkbox-table tr th {
  background-color: #aaa;
}

.checkbox-table tr th:first-child {
  padding: 15px;
}

.checkbox-table tr td:first-child {
  padding-left: 20px;
}

.checkbox-table tr:nth-child(odd) {
 background-color: #ddd;
}

.checkbox-table tr:nth-child(even) {
 background-color: #fff;
}

.checkbox-table tr:hover {
  background-color: var(--base-color, #20c7d4);
}

.checkbox-table tr:first-child th:first-child {
  border-top-left-radius: 10px;
}

.checkbox-table tr:first-child th:last-child {
  border-top-right-radius: 10px;
}

.checkbox-table tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

.checkbox-table tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.scrollable-table {
  overflow: scroll;
  max-height: 475px;
  margin-top: 15px;
  margin-bottom: 30px;
  border-radius: 10px;
  border-bottom: 2px solid grey;
}

.scrollable-table table {
  /* Cancel out attributes added to main table */
  margin: 0;
  overflow: visible;
}

.scrollable-table thead {
  position: sticky;
  top: 0;
}

.info-tooltip {
  cursor: pointer;
  margin-left: 10px;
  border: 1px solid #555;
  color: #555;
  width: 17px;
  text-align: center;
  height: 17px;
  border-radius: 50%;
  font-size: 13px;
  background-color: #fff;
  float: right;
}

.info-tooltip:hover {
  border-color: #fff;
  color: #fff;
  background-color: #555;
}

.radial-three-options {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 30px;
}

.radial-option {
  text-align: center;
}

/* -- location settings -- */

.read-only {
  background-color: #eee;
  font-size: 18px !important;
}

textarea.read-only {
  height: 55px !important;
}

/* -- location page -- */

.locations-page.new-button {
  margin-top: -50px;
}

.locations-page.widget {
  min-width: 300px;
  margin-top: 0;
}

.locations-page.widget:hover {
  background-color: #eee;
  border-color: grey;
}

.locations-page.widget h3 {
  margin-top: 0px;
  text-shadow: 1px 1px 4px #e4e4e4;
}

.locations-page.widget .icons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 100px;
  justify-items: stretch;
  text-shadow: 1px 1px 4px #e4e4e4;
}

.locations-page .no-of-people,
.locations-page .no-of-events,
.locations-page .no-of-devices {
  display: inline-block;
  padding-left: 5px;
}

.locations-page.widget .icons svg {
  margin-right: 5px;
}

.locations-page.widget .icons div:nth-child(1) {
  justify-self: start;
}

.locations-page.widget .icons div:nth-child(2) {
  justify-self: center;
}

.locations-page.widget .icons div:nth-child(3) {
  justify-self: end;
}

.full-background {
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
  background-position: center;
}

.fade {
  background-color: #ffffff61;
  padding: 10px;
  border-radius: 10px;
  height: 88%;
  position: relative;
}

.locations-page.widget:hover .fade {
  background-color: #ffffff00;
}

.toggle-hidden input {
  display: none;
}

.toggle-hidden label {
  cursor: pointer;
  font-size: 16px;
  font-style: italic;
  text-decoration: underline;
}

.hidden-locations {
  margin-top: 10px;
}

.fire-log h3 {
  display: inline-block;
}

.fire-log-title {
  color: #000;
  float: right;
  display: inline-block;
}

.fire-log-title::after {
  content: "";
  display: block;
  height: 3px;
  background-color: transparent;
  position: absolute;
  top: 100%;
  left: 0;
  position: relative;
  outline: none;
}

.fire-log:hover .widget {
  background: rgb(228,61,64);
  background: linear-gradient(45deg, #ee2d31, #f37970 57%, #fabec0 99%);
}

.fire-log:hover .fire-log-title {
  color: #fc1519;
}

.fire-log:hover .fire-log-title::after {
  background-color: #fc1519;
  text-shadow: -1px 1px 2px #ffcece;
}

.people-count {
  text-align: left;
  margin-left: 48px;
}

.people-count div {
  display: inline-block;
  margin-right: 40px;
  text-align: center;
}

.people-count .count-title {
  font-size: 20px;
  font-weight: 500;
}

.inline-grid input {
  display: inline-block;
}

.inline-grid label {
  display: inline-block;
  width: 90%;
}

.person-details {
  display: grid;
  grid-template-columns: 8fr 2fr 5fr 2fr 3fr;
}

.margin-top {
  margin-top: 20px;
}

/* -- Device page -- */
.qr-code-options {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 30px;
}


.qr-code-option {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.qr-code-option label {
  margin-left: 10px;
  cursor: pointer;
}


.sample-qr {
  height: 256px;
  width: 256px;
  border: 2px solid #666;
  background-color: #999;
  color: #fff;
  line-height: 256px;
  text-align: center;
}

.sample-qr-small {
  height: 128px;
  width: 128px;
  border: 2px solid #666;
  background-color: #999;
  color: #fff;
  line-height: 128px;
  text-align: center;

}

.reset-device {
  height: 80px;
}

.reset-device .button {
  clear: both;
}

/* -- People page -- */

.custom-attributes-table input {
  width: 98%;
  height: auto;
  line-height: 50px;
  margin-bottom: 0;
}

/* -- Role page -- */

.default-color-options {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  margin-bottom: 15px;
}

.default-color-option {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.default-color-option input {
  margin-bottom: 15px
}

.default-color-option label {
  margin-left: 10px;
  padding: 4px;
  border-radius: 7px;
  cursor: pointer;
}

/* -- Bulletins page -- */

.radio-buttons {
  display: inline-block;
  margin-bottom: 20px;
}

.radio-button {
  display: inline-block;
  text-align: center;
  margin-right: 60px;
}

.radio-button input,
.radio-button label {
  margin: 0;
  cursor: pointer
}

/* -- Feedback page -- */

.feedback-preview input[type="checkbox"] {
  width: 0;
  opacity: 0;
  padding: 0;
  margin: 0;
}
.feedback-preview .question.text {
  display: block;
  width: 80% !important;
  margin: auto;
}

.feedback-preview .radio {
  width: max-content;
  margin: auto;
}

.feedback-preview .radio label {
  border: 3px solid var(--page-border-color, #20c7d4);
  border-radius: 30px;
  padding: 20px;
  vertical-align: top;
  display: inline-block;
  cursor: pointer;
  min-width: 50px;
  margin: 10px;
  background-color: #ffffffde;
  text-align: center;
}

.feedback-preview .radio label.longer {
  width: 130px;
}

.feedback-preview input:checked + label {
  background-color: var(--page-border-color, #20c7d4);
  border-color: #000;
  text-decoration: underline;
}

.feedback-preview .radio .mobile-stars label {
  border: none;
  border-radius: 0;
  padding: 10px;
  min-width: 0;
  margin: 0;
  background-color: transparent;
  font-size: 40px;
}

.feedback-preview .radio .no:checked + label {
  background-color: #b80c09;
  border-color: #000;
  color: #ffffff;
}

.feedback-preview .radio .yes:checked + label {
  background-color: #22ee80;
  border-color: #000;
}

.smileys {
  font-size: 36px;
}

.smileys label {
  min-width: 67px !important;
}

.smileys .very_unhappy input:checked + label {
  background-color: #b80c09;
  border-color: #000;
  color: #ffffff;
}

.smileys .unhappy input:checked + label {
  background-color: #ff8819;
  border-color: #000;
}

.smileys .yellow input:checked + label {
  background-color: #eee622;
  border-color: #000;
}

.smileys .light-green input:checked + label {
  background-color: #cbee22;
  border-color: #000;
}

.smileys .very_happy input:checked + label {
  background-color: #22ee80;
  border-color: #000;
}

.smileys .very_unhappy input:not(:checked) + label {
  color: #b80c09;
}

.smileys .unhappy input:not(:checked) + label {
  color: #ff8819;
}

.smileys .yellow input:not(:checked) + label {
  color: #e6d220;
}

.smileys .light-green input:not(:checked) + label {
  color: #a4bf1b;
}

.smileys .very_happy input:not(:checked) + label {
  color: #1bbf67;
}

.feedback-preview .question{
  background-color: #fff;
  border-radius: 15px;
  padding: 10px 40px;
}

.feedback-preview input {
  font-size: 22px;
  padding-left: 6px;
  border: 1px solid #aaa;
  border-radius: 5px;
}

.button {
  display: inline-block;
  padding: 5px 20px;
  margin-left: 10px;
  border: 2px solid #666;
  background-color: #ccc;

  font-size: 22px;
  margin-bottom: 10px;
  border-radius: 5px;

  cursor: pointer;
}

.button:not([disabled]):hover {
  cursor: pointer;
  background-color: var(--base-color, #20c7d4);
  color: #000;
  font-weight: 600;
  border: 2px solid #000;

  letter-spacing: -0.54px;
}

.button[disabled] {
  color: #777777;
}

.button.centered {
  display: block;
  margin: 20px auto;
  width: max-content;
}

.button.small {
  font-size: 19px;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
}

.new-button {
  float: right;
  margin-top: 16px;
}

.red-button {
  background-color: #ea7970;
}

.big-table {
  width: 100%;
}

.big-table .heading .th {
  background-color: #ccc;
  padding: 15px;
}

.big-table .tr:nth-child(even) {
  background-color: #eee;
}

.big-table .tr:not(.heading):nth-child(odd) {
  background-color: #fff;
}

.big-table .tr.not-clickable:nth-child(even) {
  background-color: #b9fcff;
}

.big-table .tr.not-clickable:not(.heading):nth-child(odd) {
  background-color: #eefffe;
}


.big-table .td {
  padding: 10px 10px 10px 15px;
  vertical-align: middle;
}

.big-table a {
  text-decoration: none;
}

.big-table a.tr:not(.heading):hover {
  background-color: var(--base-color, #20c7d4) !important;
  text-decoration: underline;
}

.big-table .heading .th:first-child,
.big-table .heading .th.first-child {
  border-radius: 10px 0 0 0;
}

.big-table .heading .th:last-child,
.big-table .heading .th.last-child {
  border-radius: 0 10px 0 0;
}

.big-table .tr:last-child .td:first-child {
  border-radius: 0 0 0 10px;
}

.big-table .tr:last-child .td:last-child {
  border-radius: 0 0 10px 0;
}

.big-table .tr:last-child .td:last-child {
  border-radius: 0 0 10px 0;
}

.big-table .sortable {
  cursor: pointer;
}

.big-table .sortable:hover,
.big-table .sorted.th {
  background-color: #aaa;
  border-color: #777;
}

.big-table .not-sortable {
  cursor: not-allowed;
}

.big-table .not-clickable {
  cursor: not-allowed;
}


.big-table a {
  cursor: pointer !important;
}

.td.center {
  text-align: center;
}

.input-in-table {
  width: 96%;
  padding: 0 6px;
  margin: 7px 7px 7px -7px;
}

.button-in-table {
  margin-left: 0;
  margin-bottom: 0;
}

.amend {
  text-align: center;
  display: inline-block;
  padding: 5px 10px 5px 10px;
  border-radius: 10px;
  color: #B80C09;
  background-color: #EEE622;
  cursor: pointer;
}

.green-button {
  text-align: center;
  display: inline-block;
  padding: 5px 10px 5px 10px;
  border-radius: 10px;
  color: #fff;
  background-color: #158E4D;
  cursor: pointer;
}

.no-access {
  text-align: center;
  display: inline-block;
  padding: 5px 10px 5px 10px;
  border-radius: 10px;
  color: #555;
  background-color: lightgrey;
}
.visited-people-table {
  width: 100%;
}

.visited-people-table .heading .th {
  background-color: #aaa;
  border: 1px solid #888;
  padding: 15px;
}

.visited-people-table .tr:not(.heading) {
  height: 45px;
}
.visited-people-table .tr:nth-child(even) .td {
  background-color: #fff;
}

.visited-people-table .tr:not(.heading):nth-child(odd) .td {
  background-color: #ddd;
}

.visited-people-table .td {
  border: 1px solid #aaa;
  padding-left: 15px;
  line-height: 45px;
}

.visited-people-table a {
  text-decoration: none;
}

.visited-people-table a.tr:not(.heading):hover .td {
  background-color: var(--base-color, #20c7d4);
  text-decoration: underline;
}

.visited-people-table .heading .th:first-child {
  border-radius: 10px 0 0 0;
}

.visited-people-table .heading .th:last-child {
  border-radius: 0 10px 0 0;
}

.visited-people-table .tr:last-child .td:first-child {
  border-radius: 0 0 0 10px;
}

.visited-people-table .tr:last-child .td:last-child {
  border-radius: 0 0 10px 0;
}

.visited-people-table .tr .td:last-child {
  text-align: center;
  padding: 0px;
}

.visited-people-table .tr .td:nth-last-child(2) {
  text-align: center;
  padding: 0px;
}

.visited-people-table .tr .td:nth-last-child(3) {
  text-align: center;
  padding: 0px;
}

.bottom-divider .td {
  border-bottom: 3px solid #aaa;
}

.top-divider .td {
  border-top: 3px solid #aaa;
}

.left-divider {
  border-left: 3px solid #aaa;
}

.right-divider {
  border-right: 3px solid #aaa;
}

/* -- drag and drop -- */

.drag-and-drop .row {
  display: grid;
}

.drag-and-drop .three-columns {
  grid-template-columns: 0.3fr 0.6fr 1fr;
}

.drag-and-drop .six-columns {
  grid-template-columns: 0.3fr 0.6fr 1fr 1fr 0.7fr 0.6fr;
}

.drag-and-drop .seven-columns {
  grid-template-columns: 0.3fr 0.6fr 1fr 1fr 0.7fr 0.5fr 0.5fr;
}

.drag-and-drop .drag-icon {
  text-align: center;
}

.drag-and-drop .three-columns.heading {
  width: 100%;
}

.drag-and-drop .heading {
  background-color: #ccc;
}

.drag-and-drop .heading .th {
  padding: 15px;
}

.drag-and-drop .row:nth-child(even) {
  background-color: #eee;
}

.drag-and-drop .td {
  padding: 10px 10px 10px 15px;
}

.drag-and-drop a {
  text-decoration: none;
}

.drag-and-drop a.row:not(.heading):hover {
  background-color: var(--base-color, #20c7d4) !important;
  text-decoration: underline;
}

.drag-and-drop a.row:last-child {
  border-radius: 0 0 10px 10px;
}

.drag-and-drop .heading {
  border-radius: 10px 10px 0 0;
}

.drag-and-drop .heading .th:first-child {
  border-radius: 10px 0 0 0;
}

.drag-and-drop .heading .th:last-child {
  border-radius: 0 10px 0 0;
}

.drag-and-drop .row:last-child,
.drag-and-drop .row:last-child .td:first-child {
  border-radius: 0 0 0 10px;
}

.drag-and-drop .row:last-child,
.drag-and-drop .row:last-child .td:last-child {
  border-radius: 0 0 10px 0;
}

/* -- analysis section -- */

.analysis-page .react-tabs__tab-list {
  margin: 0 0 25px;
}

.analysis-page .date-selection {
  margin-left: 10px;
}

.date-selection select {
  font-size: 18px;
  margin-right: 20px;
}

label.column {
  margin-right: 10px;
}

label.rightcolumn {
  float: right
}


label.pcolumn {
  margin-right: 10px;
  padding-left: 10px;
}

label.prightcolumn {
  float: right;
  padding-right: 10px;
}

input[type="date"] {
  margin-right: 20px;
}

.text-answer {
  width: 500px;
  height: 340px;
}

.text-answer div {
  text-align: center;
  margin-top: 100px;
}

/* -- export selection -- */

.export-selection label {
  margin-right: 7px;
}

.export-selection input[type="date"] {
  margin-right: 20px;
}

.export-selection select {
  font-size: 18px;
  margin-right: 20px;
}

.export-selection input {
  font-size: 18px;
  margin-right: 20px;
}

.selection-block {
  display: inline-block;
  padding-top: 5px;
}

.selection-block.right-label input {
  margin-right: 7px;
  margin-left: 10px;
}

.selection-block.right-label label {
  margin-right: 10px;
}

.big-checkbox input[type="checkbox"] {
  cursor: pointer;
  width: 34px;
  height: 34px;
}

.big-checkbox label {
  vertical-align: super;
  margin-left: -5px;
}

.exports-page.widget {
  min-width: 280px;
  margin-top: 0;
  padding: 10px;

  background-color: #ffffff81;
  min-height: 140px;
}

.exports-page.widget:hover {
  background-color: #eee;
  border-color: grey;
}

.exports-page.widget h3 {
  margin-top: 0px;
  text-shadow: 1px 1px 4px #e4e4e4;
  padding: 5px 9px;
}

.centered-text {
  width: max-content;
  margin: auto;
  display: block;
  float: unset;
}

.positive-feedback {
  background-color: #22ee80 !important;
  color: #000 !important;
}

.positive-feedback:not(.not-clickable):hover {
  color: #fff !important;
}

.neutral-feedback {
  background-color: #eee622 !important;
  color: #000 !important;
}

.neutral-feedback:not(.not-clickable):hover {
  color: #0058ff !important;
}

.negative-feedback {
  background-color: #ce5252 !important;
  color: #000 !important;
}

.negative-feedback:not(.not-clickable):hover {
  color: #fff !important;
}

.open-action {
  background-color: #eee622 !important;
  color: #000 !important;
}

.open-action:not(.not-clickable):hover {
  color: #0058ff !important;
}

.positive-feedback:not(.not-clickable):hover .open-action {
  color: #0058ff !important;
}

.neutral-feedback:not(.not-clickable):hover .open-action {
  color: #0058ff !important;
}

.negative-feedback:not(.not-clickable):hover .open-action {
  color: #0058ff !important;
}

.scoreless-feedback:not(.not-clickable):hover .open-action {
  color: #0058ff !important;
}

.started-action {
  background-color: #3cdaff !important;
  color: #000 !important;
}

.started-action:not(.not-clickable):hover {
  color: #fff !important;
}

.positive-feedback:not(.not-clickable):hover .started-action {
  color: #fff !important;
}

.neutral-feedback:not(.not-clickable):hover .started-action {
  color: #fff !important;
}

.negative-feedback:not(.not-clickable):hover .started-action {
  color: #fff !important;
}

.scoreless-feedback:not(.not-clickable):hover .started-action {
  color: #fff !important;
}

.awaiting-sign-off-action {
  background-color: #f29e32 !important;
  color: #000 !important;
}

.awaiting-sign-off-action:not(.not-clickable):hover {
  color: #fff !important;
}

.positive-feedback:not(.not-clickable):hover .awaiting-sign-off-action {
  color: #fff !important;
}

.neutral-feedback:not(.not-clickable):hover .awaiting-sign-off-action {
  color: #fff !important;
}

.negative-feedback:not(.not-clickable):hover .awaiting-sign-off-action {
  color: #fff !important;
}

.scoreless-feedback:not(.not-clickable):hover .awaiting-sign-off-action {
  color: #fff !important;
}

.blocked-action {
  background-color: #ce5252 !important;
  color: #fff !important;
}

.blocked-action:not(.not-clickable):hover {
  color: #fff !important;
}

.positive-feedback:not(.not-clickable):hover .blocked-action {
  color: #fff !important;
}

.neutral-feedback:not(.not-clickable):hover .blocked-action {
  color: #fff !important;
}

.negative-feedback:not(.not-clickable):hover .blocked-action {
  color: #fff !important;
}

.scoreless-feedback:not(.not-clickable):hover .blocked-action {
  color: #fff !important;
}

.completed-action {
  background-color: #22ee80 !important;
  color: #000 !important;
}

.completed-action:not(.not-clickable):hover {
  color: #fff !important;
}

.positive-feedback:not(.not-clickable):hover .completed-action {
  color: #fff !important;
}

.neutral-feedback:not(.not-clickable):hover .completed-action {
  color: #fff !important;
}

.negative-feedback:not(.not-clickable):hover .completed-action {
  color: #fff !important;
}

.scoreless-feedback:not(.not-clickable):hover .completed-action {
  color: #fff !important;
}

.abandoned-action {
  background-color: #8789d5 !important;
  color: #fff !important;
}

.abandoned-action:not(.not-clickable):hover {
  color: #fff !important;
}

.positive-feedback:not(.not-clickable):hover .abandoned-action {
  color: #fff !important;
}

.neutral-feedback:not(.not-clickable):hover .abandoned-action {
  color: #fff !important;
}

.negative-feedback:not(.not-clickable):hover .abandoned-action {
  color: #fff !important;
}

.scoreless-feedback:not(.not-clickable):hover .abandoned-action {
  color: #fff !important;
}

.no-action {
  background-color: #fff !important;
  color: #000 !important;
}

.no-action:not(.not-clickable):hover {
  background-color: var(--base-color, #20c7d4) !important;
}

.positive-feedback:not(.not-clickable):hover .no-action {
  background-color: var(--base-color, #20c7d4) !important;
  color: #000 !important;
}

.neutral-feedback:not(.not-clickable):hover .no-action {
  background-color: var(--base-color, #20c7d4) !important;
  color: #000 !important;
}

.negative-feedback:not(.not-clickable):hover .no-action {
  background-color: var(--base-color, #20c7d4) !important;
  color: #000 !important;
}

.scoreless-feedback:not(.not-clickable):hover .no-action {
  background-color: var(--base-color, #20c7d4) !important;
  color: #000 !important;
}

/* -- settings section -- */

.settings-page {
  display: grid;
  grid-template-columns: 250px auto;
}

.sidebar {
  border-right: 3px solid #666;
  min-height: 100vh;
  padding-top: 3px;
}

.sidebar a {
  display: block;
  text-decoration: none;
  padding: 10px 15px;
  border-bottom: 2px solid #666;
  font-weight: 600;
}

.sidebar a.current-page {
  background-color: var(--accent-color, #70e1e9);
}

.sidebar a:hover {
  background-color: var(--base-color, #20c7d4);
}

.sidebar a.current-page:hover {
  background-color: var(--accent-color, #70e1e9);
}

.main-content {
}

.segment-heading {
  margin-top: -10px;
}

.reminder-table {
  max-width: 700px;
  margin-right: auto;
  display: grid;
  grid-template-columns: auto 1fr 1fr 1fr;
}

.reminder-options {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
}

.reminder-option.header {
  margin-bottom: 15px;
}

.reminder-option {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.reminder-option.text {
  text-align: right;
  margin-right: 0px;
  padding-top: 5px;
}

.reminder-option label {
  cursor: pointer;
}
.reminder-option input[type="checkbox"] {
  margin:0px 10px 30px 10px;
}

/* -- support section -- */
.support-resources {
  display: inline-block;
  min-width: 31%;
  vertical-align: top;
}

/* -- feedback question section -- */

.ask-frequency {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-bottom: 30px;
}

.question-types {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  margin-bottom: 30px;
}

.answer-quantity {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 30px;
}

.question-type {
  text-align: center;
}

.question-type input {
  margin-bottom: 7px;
}

.question-type label {
  cursor: pointer;
  margin-left: 10px;
}

.exports-page.widget {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
}

.alert-processing .alert-title {
  margin-bottom: 10px;
}

.alert-processing .alert-title {
  margin-top: 9px;
}

.alert-processing input[type="checkbox"] {
  margin: 0 10px 0 0;
}

.alert-processing .checkbox-label {
  margin-right: 0;
}

.alert-processing.title {
  margin-top: 0;
}

.invalid {
  border: 2px solid #dd3e31 !important;
}

.email-row {
  display: grid;
  grid-template-columns: auto 40px;
}

.email-row .remove-button {
  border: 2px solid #666;
  background-color: #ccc;
  font-size: 22px;
  border-radius: 5px;
  height: 31px;
  width: 31px;
  text-align: center;
  margin-left: 10px;
}

.rotate-90 {
  transform: rotate(-90deg);
}

/* -- relationships -- */

.remove-button {
  font-weight: 600;
  cursor: pointer;
}

.remove-button:hover {
  font-weight: 700;
  color: #f51a1e;
}

/* -- Staff Fulfilment Flow -- */

#staff-filfilment-help-text {
  margin-bottom: 30px;
}

#staff-fulfilment-flow {
  background-color: #eee;
  border-radius: 15px;
  margin-bottom: 30px;
  padding: 30px;
  text-align: center;
  z-index: 0;
  position: relative;
}

#staff-fulfilment-flow ul {
  list-style: none;
  display: inline-table;
}

#staff-fulfilment-flow ul li {
  display: inline;
}

#staff-fulfilment-flow .box {
  display: inline-block;
  background: #bbb;
  text-align: center;
  padding: 15px 20px 15px 40px;
  position: relative;
  margin: 0 8px 0 0;
  color: #000;
  cursor: pointer;
  font-weight: 600;
  font-size: 17px;
}

#staff-fulfilment-flow .box a {
  text-decoration: none;
}

#staff-fulfilment-flow .box.current {
  background: #22c7d3;
}

#staff-fulfilment-flow .box.current:after {
  border-left: 25px solid #22c7d3 !important;
}

#staff-fulfilment-flow .box:after {
  content: "";
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 25px solid #bbb;
  position: absolute; right: -25px; top: 0;
  z-index: 1;
}

#staff-fulfilment-flow .box:before {
  content: "";
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 25px solid #eee;
  position: absolute; left: 0; top: 0;
}

#staff-fulfilment-flow ul li:first-child .box {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-left: 30px;
}

#staff-fulfilment-flow ul li:first-child .box:before {
  display: none;
}

#staff-fulfilment-flow ul li:last-child .box {
  padding-right: 30px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

#staff-fulfilment-flow ul li:last-child .box:after {
  display: none;
}

#staff-fulfilment-flow .box:hover:not(.disabled) {
  background: #eee622;
}

#staff-fulfilment-flow .box:hover:not(.disabled):after {
  border-left-color: #eee622 !important;
}

#staff-fulfilment-flow .box.disabled {
  color: #777;
}

#staff-fulfilment-flow .box.disabled:hover {
  cursor: not-allowed;
}

/* Staff Fulfilment Day Boxes */

.fulfilment-day-options {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.fulfilment-day-option {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.fulfilment-day-options .fulfilment-day-option input {
  margin: 0 auto;
  display: block;
}

.fulfilment-day-option label {
  padding: 15px 4px;
  border-radius: 7px;
  cursor: pointer;
}

.fulfilment-day-button {
  text-align: right;
}

.fulfilment-day-button .button {
  font-size: 18px;
  margin-bottom: 20px;
}

/* -- Staff Fulfilment -- */

.fulfilment .switch-buttons-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: stretch;
  margin-bottom: 20px;
}

.fulfilment .switch-buttons div,
.fulfilment .switch-buttons a {
  display: inline-block;
  background-color: #eee;
  border-radius: 10px;
  padding: 9px 20px;
  border: 1px solid #aaa;
  box-shadow: 1px 1px 1px #00000054;
  margin: 5px;
  cursor: pointer;
}

.fulfilment .switch-buttons div.active,
.fulfilment .switch-buttons a.active {
  background-color: #22c7d3;
  font-weight: bold;
  cursor: default;
}

.fulfilment .switch-buttons div:hover:not(.active),
.fulfilment .switch-buttons a:hover:not(.active) {
  background-color: #eee622;
}

.fulfilment .default-or-weekly {
  margin-left: 40px;
}

.fulfilment .rotas {
  overflow-x: scroll;
  white-space: nowrap;
}

.fulfilment .container {
  display: inline-block;
  background-color: #eee;
  border-radius: 25px;
  padding: 20px;
  margin: 0 20px;
  vertical-align: top;
}

.fulfilment .container.past {
  background-color: #cfcfcf;
}

.fulfilment .container.present,
.fulfilment .container.future {
  background-color: #eee;
}

.fulfilment .title {
  text-align: center;
  font-size: 24px;
}

.fulfilment .graph {
}

.fulfilment .roles {
  display: grid;
  grid-template-columns: 130px 720px;
  position: relative;
}

.fulfilment .roles.with-people {
  display: grid;
  grid-template-columns: 1fr 720px;
}

.fulfilment .roles:first-child {
}

.fulfilment .roles:first-child .left .role-label {
  position: absolute;
  bottom: 0;
  left: 33%;
}

.fulfilment .roles:first-child .left .time-label {
  transform: rotate(270deg);
  position: absolute;
  right: -8px;
  top: 38%;
}

.fulfilment .roles:first-child .right {
  min-height: auto;
}

.fulfilment .roles:first-child .hours {
  margin-top: 20px;
}

.fulfilment .left {
  position: relative;
  border-bottom: 2px solid #666;
}

.fulfilment .right:not(.config) {
  position: relative;
  border-bottom: 2px solid #666;
  min-height: 124px;
}

.config {
  position: absolute;
  top: 170px;
  right: 10px;
}

.staff-fulfilment-config-explainer {
  width: 316px;
  font-size: 16px;
  padding-bottom: 20px;
}

.divisor-line {
  width: 100%;
  border-top: 2px solid #000;
  float: right;
  margin: 10px;
}

.indented-content {
  width: 95%;
  float: right;
}

.divided-columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
}

.divided-columns input[type="date"] {
  margin-right: 0px;
}

.update-working-pattern .divided-columns input[type="date"] {
  width: 97%;
}

.update-working-pattern .divided-columns input[type="number"] {
  width: 97%;
}

.role-preview-color {
  padding: 10px;
  padding-left: 20px;
  border-radius: 25px;
}

.fulfilment .role-title {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.role-title-container {
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.fulfilment .role-title.sideways {
  writing-mode: vertical-rl;
  height: fit-content;
}

.fulfilment .role-title .bubble {
  border: 1px solid #eee;
  background-color: #aaaaaa;
  border-radius: 9px;
  padding: 10px;
  margin: 0 auto 12px;
}

.fulfilment .role-title .hour-count {
  white-space: break-spaces;
  font-size: 11px;
  text-align: center;
}

.fulfilment .right:hover + .new-shift {
  opacity: 1;
}

.fulfilment .new-shift {
  cursor: pointer;
  border-radius: 5px;
  position: absolute;
  right: -45px;
  top: 29%;
  background: #eee;
  font-size: 30px;
  line-height: 20px;
  opacity: 0;
}

.fulfilment .new-shift:hover {
  background-color: #dadada;
  opacity: 1;
}

.fulfilment .new-shift .plus-button {
  padding: 15px;
  margin-top: -2px;
}

.fulfilment .left .row-labels {
  display: grid;
  font-size: 8px;
  height: 100%;
  align-items: center;
  float: right;
  margin-right: 3px;
}

.fulfilment .with-people .left .row-labels {
  padding-left: 50px;
}

.fulfilment .left .row-labels .person {
  height: 25px;
  line-height: 25px;
  font-size: 12px;
  text-align: right;
}
.fulfilment .left .row-labels .contracted-hours {
  height: 20px;
  font-size: 11px;
  text-align: right;
  line-height: 3px;
}
.fulfilment .roles:last-child .left,
.fulfilment .roles:last-child .right {
  border-bottom: none
}

.fulfilment .hours {
  display: grid;
  height: 100%;
  /* grid-template-columns -> overridden in file */
}

.fulfilment .hour-title {
  border-left: 2px solid #bbb;
  text-align: center;
  overflow: hidden;
}

.fulfilment .hour-title:first-child {
  border-left: none;
}

.fulfilment .hour-column {
  border-left: 2px solid #bbb;
  text-align: center;
  cursor: pointer;
}

.fulfilment .hour-column:hover {
  background-color: #dadada;
}

.fulfilment .hour-column:first-child {
  border-left: none;
}

.fulfilment .left {
  border-right: 2px solid #666;
}


.fulfilment .rota-hours {
  position: absolute;
  bottom: 1px;
  margin-left: 1px;
  display: grid;
  width: 100%;
  height: 98.5%;
  /* grid: -> overridden in file;*/
}

.fulfilment .rota-hours:hover {
    cursor: pointer;
}

.fulfilment .allocated-shift {
  float: left;
  background-color: #aaa;
  color: #727272;
  overflow: hidden;
}
.fulfilment .allocated-shift.exception {
  float: left;
  background-color: #33D;
  color: #ccc;
  overflow: hidden;
}

.fulfilment .allocated-shift .number-of-people {
  font-size: 37px;
  line-height: 40px;
  font-weight: 500;
}

.fulfilment .allocated-shift .time {
  font-size: 11px;
  line-height: 7px;
  top: 40px;
}

.fulfilment .allocated-shift:hover {
  background: #999;
  cursor: pointer;
  color: #4f4f4f;
}
.fulfilment .allocated-shift.exception:hover {
  background: #22C;
  cursor: pointer;
  color: #bbb;
}

.assigned-hours {
  width: 100%;
}

.assigned-hours div {
  position: absolute;
  height: 100%;
  width: 100%;
  cursor: pointer;
  margin-top: -1px;
}

.assigned-hours .green {
  background-color: #23ed80;
  background: linear-gradient(0deg, #81f9ba 0%, #23ed80 30%, #23ed80 100%);
}

.assigned-hours .orange {
  background-color: #ff8819;
  background: linear-gradient(0deg, #cc6e14 0%, #ff8819 30%, #ff8819 100%);
}

.assigned-hours .red {
  background-color: #ce5252;
  background: linear-gradient(0deg, #933333 0%, #ce5252 30%, #ce5252 100%);
}

.assigned-hours .yellow {
  background-color: #eee622;
  background: linear-gradient(0deg, #ccc522 0%, #eee622 30%, #eee622 100%);
}

.assigned-hours .green-lunch-pattern {
  background-color: #23ed80;
  background: repeating-linear-gradient(135deg, #666, #777 1px, #23ed80 3px, #23ed80 7px);
}

.assigned-hours .orange-lunch-pattern {
  background-color: #ff8819;
  background: repeating-linear-gradient(135deg, #666, #777 1px, #ff8819 3px, #ff8819 7px);
}

.assigned-hours .green:hover {
  background-color: #23ed80;
  background: linear-gradient(0deg, #81f9ba 0%, #23ed80 100%, #23ed80 100%);
}

.assigned-hours .orange:hover {
  background-color: #ff8819;
  background: linear-gradient(0deg, #cc6e14 0%, #ff8819 100%, #ff8819 100%);
}

.assigned-hours .red:hover {
  background-color: #ce5252;
  background: linear-gradient(0deg, #933333 0%, #ce5252 100%, #ce5252 100%);
}

.assigned-hours .yellow:hover {
  background-color: #eee622;
  background: linear-gradient(0deg, #ccc522 0%, #eee622 100%, #eee622 100%);
}

.assigned-hours .green-lunch-pattern:hover {
  background-color: #23ed80;
  background: repeating-linear-gradient(135deg, #666, #777 1px, #81f9ba 3px, #81f9ba 7px);
}

.assigned-hours .orange-lunch-pattern:hover {
  background-color: #ff8819;
  background: repeating-linear-gradient(135deg, #666, #777 1px, #cc6e14 3px, #cc6e14 7px);
}

.half-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.half-row select {
  width: 90%;
  float: left;
}

.half-row input[type="date"] {
  width: 90%;
}

.half-row input[type="time"] {
  width: 90%;
  float: left;
}

.half-row input[type="number"] {
  width: 90%;
}

.full-row input[type="date"] {
  width: 100%;
}

.full-row input[type="time"] {
  width: 100%;
  margin-bottom: 0px;
}

.big-table select,
.big-table input {
  margin-bottom: 0px;
}

.no-margin {
  margin: 0 0 0 0 !important;
}

/* -- worked hours -- */

.worked-hours-table tr:not(.heading):hover {
  background-color: #abf3f9 !important;
}

/* -- support section -- */

.faq-search-box {
  margin: 100px 0;
}

.faq-search-box h3 {
  display: block;
  text-align: left;
  margin-top: 0;
}

.faq-search-box .input-box {
  text-align: center;
}

.faq-search-box input {
  margin: 0 auto;
  width: 90%;
  height: 50px;
  background-color: #f9f9f9ee;
  box-shadow: 1px 1px 6px #5c5c5c;
  border: 1px solid #000;
  border-radius: 11px;
  font-size: 30px;
  padding-left: 15px;
}

.answers {
  width: 90%;
  margin: 0 auto;
}

.loading-segment {
  background-color: #e5e5e5;
  display: block;
  padding: 30px;
  border-bottom: 1px solid #000;
}

.loading-segment h3 {
  margin: 0;
}

.faq-segment {
  background-color: #e5e5e5;
  display: block;
  padding: 30px;
  border-bottom: 1px solid #000;
}

.faq-segment:hover {
  background-color: var(--base-color, #20c7d4);
}

.faq-segment p,
.faq-segment div {
  display: inline;
}

.faq-answer img {
  max-width: 100%;
}

.faq-search-box .score-comments {
  float: right;
}

.inline-score-comments {
  display: block !important;
  text-align: right;
  margin-top: 20px;
}

.voting-box {
  margin: 0 auto;
  text-align: center;
  width: fit-content;
  background-color: #eee;
  padding: 12px 30px;
  border-radius: 20px;
  border: 1px solid #999;
  margin-top: 70px;
}

.voting-box .thumbs div {
  display: inline-block;
}

.voting-box .thumbs svg {
  height: 1.5em;
}

.voting-box .thumbs .score {
  font-size: 41px;
  margin: 0px 15px;
  vertical-align: sub;
  min-width: 30px;
}

.vote-option {
  margin: 10px;
  padding: 10px 28px;
  border: 1px solid #000;
  border-radius: 5px;
  box-shadow: 1px 1px 1px 0px #040404;
}

.vote-option.clicked {
  color: #fff;
  background-color: var(--base-color, #20c7d4);
  box-shadow: inset 0px 0px 2px 0px #040404;
}

.subtle-link {
  text-decoration-line: none !important;
  cursor: pointer;
}

.subtle-link:hover {
  text-decoration-line: revert !important;
}

.inline-faq-search input {
  width: 98.7%;
  margin-bottom: 0;
}

.new-ticket-button {
  margin: 0 auto;
  text-align: center;
  display: block;
  margin-top: 55px;
  margin-bottom: 30px;
}

.ticket-body .body-title {
  display: inline-block;
  vertical-align: top;
}

.ticket-body .body-title .status-text {
  line-height: 31px;
}

.ticket-body .body-content {
  display: inline-block;
}

.action-status {
  border-radius: 6px;
  padding: 5px 8px;
}


.ticket-body .image-content {
  max-width: 800px;
  max-height: 400px;
}

.user-response {
  text-align: right;
}

.admin-response {
  background-color: #a7f9ff;
  box-shadow: 1px 1px 5px 0px #72dde2;
}

.unread {
  background-color: #a7f9a7;
}

.unread-ticket {
  background-color: #dd3e31;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-left: -22px;
  margin-top: -17px;
}

.ticket-status {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  grid-gap: 1rem;
  text-align: center;
  padding: 0 6%;
  margin-top: 29px;
}

.ticket-status:before,
.ticket-status:after {
  content: "";
  display: block;
  border-top: 2px solid #000;
}

.unread-status {
  color: #50f050;
}

.unread-status:before,
.unread-status:after {
  content: "";
  display: block;
  border-top: 2px solid #70f970;
}

.first-unread-reply {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  grid-gap: 1rem;
  text-align: center;
  padding: 0 6%;
  margin-top: 29px;
  color: #50f050;
}

.first-unread-reply:before,
.first-unread-reply:after {
  content: "";
  display: block;
  border-top: 2px solid #70f970;
}

/* -- Location Select Component -- */

.locations-component.widget {
  min-width: 300px;
  height: 160px;
  margin-top: 0;
}

.locations-component.widget:hover, .rota-type-component.widget:hover {
  background-color: #eee;
  border-color: grey;
}

.rota-type-component.widget {
  min-width: 230px;
  height: 150px;
  margin-top: 0;
  vertical-align: bottom;
  overflow: hidden;
  position: relative;
}

.edit-cog {
  display: none;
  position: absolute;
  font-size: 25px;
  right: 7px;
  top: 6px;
  z-index: 10;
}

.edit-cog:hover {
  color: #eee;
}

.rota-type-component.widget:hover .edit-cog {
  display: block;
}

/* -- update success popup prompt section -- */

.update-popup {
  position: fixed;
  z-index: 2;
  top: 95%;
  left: 50%;
  white-space: pre;
  border: 2px solid #47ad07;
  border-radius: 4px;
  box-shadow: 1px 1px #030;
  background-color: #bee3a6;
  color: #000;
  text-align: center;
  width: min-content;
  height: min-content;
  padding: 10px;
  margin: 0 auto;
  margin-bottom: 26px;
  padding-right: 25px;
  transform: translate(-50%, -50%);
  cursor: pointer;
  animation: fadeout 1.5s 1;
  -webkit-animation: fadeout 1.5s 1;
  animation-delay: 3.5s;
  -webkit-animation-delay: 3.5s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

.slow {
  animation-delay: 8.5s !important;
}

.close-button {
  display: inline;
  z-index: 2;
  color: #000;
  float: right;
  padding: 0px;
  margin-right: -17px;
  margin-top: 3.5px;
  align-items:  right;
  text-align:  right;

}

@keyframes fadeout {
    from {opacity :1;}
    to {opacity :0;}
}

@-webkit-keyframes fadeout {
    from {opacity :1;}
    to {opacity :0;}
}

.center-cell {
  display: table-cell;
  text-align: center;
  padding: 0px;
  border: 1px solid #aaa;
}

.soft-table {
  width: 100%;
  margin: auto;
  overflow: hidden;
  border-radius: 10px;
}

.soft-table .heading {
  background-color: #ccc;
}

.soft-table .heading .th {
  padding: 15px;
}

.soft-table .tr:nth-child(even) {
  background-color: #eee;
}

.soft-table .tr:not(.heading):nth-child(odd) {
  background-color: #fff;
}

.soft-table .td {
  padding: 10px 10px 10px 15px;
}

.soft-table a {
  text-decoration: none;
}

.soft-table a.tr:not(.heading):hover {
  background-color: var(--base-color, #20c7d4);
  text-decoration: underline;
}

.soft-table label.pcolumn {
  padding-left: 16px;
  margin-top: 7px;
  margin-bottom: 7px;
}

.soft-table label.prightcolumn {
  padding-right: 16px;
  margin-top: 7px;
  margin-bottom: 7px;
}

.tall-tr {
  height: 45px;
}

/* -- Merge People page -- */

.merge-title {
  margin: 10px 0;
}

.merge-people-columns {
  display: grid;
  grid-template-columns: 1fr 80px 1fr;
  column-gap: 20px;
}

.two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
}

.merge-results {
  margin-bottom: 40px;
}

.merge-results {
  display: table;
  width: 100%;
}

.merge-results .row {
  display: table-row;
}

.merge-results .row:nth-child(odd) {
  background-color: #ddd;
}

.merge-results .row:nth-child(even) {
  background-color: #fff;
}


.merge-results .cell {
  display: table-cell;
  padding: 8px;
}

.person-swap {
  margin: auto;
  width: auto;
  height: 34px;
  display: inline-block;
  padding: 0 20px;
  border: 2px solid #666;
  background-color: #ccc;
}

.person-swap:not([disabled]):hover {
  cursor: pointer;
  background-color: var(--base-color, #20c7d4);
  color: #000;
  font-weight: 600;
  border: 2px solid #000;
}


.bold {
  font-weight: 600;
}

/* -- add custom attribute modal -- */

.full-modal-container {
  position: fixed;
  left: -100%;
  right: 0%;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  height: 100%;
  border-radius: 10px;
  width: 200vw;
}

.full-modal-content {
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 30px 60px;
  position: fixed;
  top: 50%;
  left: 16%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 60px 0px #000000bd;
  width: 80vw;
  max-height: 90vh;
  overflow: auto;
}

.full-modal-content.minimal {
  width: max-content;
  max-width: 80vw;
}

.modal-container {
  position: fixed;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  height: 100%;
  border-radius: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 15px;
  padding: 30px 60px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 60px 0px #000000bd;
  max-height: 100%;
  overflow: auto;
  cursor: default;
}

.modal-content.manual-check-out {
  min-width: 690px;
}

.modal-title {
  margin: auto;
  width: fit-content;
  font-size: 22px;
  font-weight: bold;
  padding-bottom: 20px;
}

.center-two-buttons {
  width: auto;
  margin: 0 auto;
  text-align: center;
}

.modal-button {
  height: 50px;
  width: auto;
  font-size: 20px;
  margin: 30px 10px 0px;
  border-radius: 5px;
}

.modal-button:not([disabled]):hover {
  cursor: pointer;
  background-color: var(--base-color, #20c7d4);
  color: #000;
  font-weight: 600;
  border: 2px solid #000;
}

/* -- send email modal -- */

.send-email-container {
  position: fixed;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  height: 100%;
  border-radius: 10px;
}

.send-email-content {
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 30px 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: max-content;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 60px 0px #000000bd;
}

.send-email-content input {
  width: 100%;
  font-size: 20px;
}

.send-email-button {
  height: 50px;
  width: 160px;
  font-size: 20px;
  margin: 30px 10px 0px;
  border-radius: 5px;
}

.send-email-button:hover {
  cursor: pointer;
  background-color: var(--base-color, #20c7d4);
  color: #000;
  font-weight: 600;
  border: 2px solid #000;
}

.grid-2-centered-columns {
  display: grid;
  grid-template-columns: 0.15fr 1fr 1fr 0.15fr;
}

.reset-password-link {
  margin-top: 20px;
}

.reset-password-link input {
  margin-top: 10px;
  margin-bottom: 0px;
}

/* -- information and warning boxes -- */

.information-box {
  color: #fff;
  padding: 12px 9px;
  font-weight: 700;
  align-items: center;
  display: flex;
  background-color: #4299e1;
  margin-bottom: 15px;
  border-radius: 5px;
}

.information-box .icon {
  padding: 0 12px;
}

.warning-box {
  color: #c53030;
  padding: 12px 9px;
  align-items: center;
  background-color: #ebdfdf;
  border: 1px solid #c53030;
  border-radius: 5px;
  margin-bottom: 15px;
}

.warning-box a {
  color: #c53030;
  font-weight: 600;
}

.orange {
  background-color: #FF8819 !important;
}

/* -- Colour Tester Box -- */

.color-preview {
  height: 30px;
  border-radius: 40px;
  margin-top: -20px;
  margin-bottom: 20px;
}

.td .color-preview {
  margin: auto;
  height: 24px;
  max-width: 80%;
}

/* -- Planned Events -- */

.contextual-date {
  margin-left: 15px;
}

.loading {
  cursor: progress;
}

.legend-box {
  width: 50%;
  background-color: #eee;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  margin-top: -155px;
}

.legend {
  color: #FFF;
  background-color: #1db6c2;
}

.grey-event {
  background-color: #ccc !important;
  color: #000 !important;
}

.yellow-event {
  background-color: #EEE622 !important;
  color: #000 !important;
}

.green-event {
  background-color: #1AC267 !important;
}

.purple-event {
  background-color: #8826BF !important;
}

.small.button {
  font-size: 18px !important;
}

.td.with-button {
  line-height: normal;
  max-width: 50%;
}

.top-space {
  margin-top: 30px;
}
.add-person {
  min-width: 40%;
}

.bigger-font {
  font-size: 22px;
}

.right {
  float: right;
}

.search-select {
  margin: auto;
  display: block !important;
  width: 60% !important;
  margin-bottom: 0px !important;
}

.search-text {
  margin: auto;
  display: block !important;
  width: 58.5% !important;
  margin-bottom: 0px !important;
  height: 30px;
  font-size: 22px;
  padding-left: 6px;
  border: 1px solid #aaa;
  border-radius: 5px;
}

.search-text.small-bottom-margin {
  margin-bottom: 10px !important;
}

.search-text.full-width {
  width: 98.5% !important;
}

.under-search {
  margin-top: 40px !important;
}

.react-select {
  font-size: 18px;
  margin-right: 20px;
  border: 1px solid #767676;
  border-radius: 3px;
  min-width: 250px;
  display: inline-block;
}

.react-select .react-select__control {
  min-height: 0;
  padding: 0 8px;
  margin: 3px 0;
}

.react-select .react-select__menu {
  background: #e3e4e3;
  border: 1px solid #c4c4c4;
  box-shadow: 0px 0px 1px #000;
  padding: 10px;
}

.react-select .react-select__option {
  padding: 5px 10px;
}

.react-select .react-select__option:hover {
  background-color: #699ff8;
  border-radius: 5px;
}

.react-select__multi-value {
  margin-right: 5px;
}

.react-select__multi-value__label {
  background-color: #ccc;
  padding: 0 5px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.react-select__multi-value__remove {
  background-color: #ccc;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 0 3px;
}
.react-select__multi-value__remove:hover {
  background-color: #ffbdad;
  color: #de350b;
}

.button.highlighted {
  cursor: pointer;
  background-color: var(--base-color, #20c7d4);
  color: #000;
  font-weight: 600;
  border: 2px solid #000;
}

.tall-line {
  margin-bottom: 10px;
  padding: 10px;
}

.title-help-text {
  float: left;
  margin-top: 5px;
  margin-right: 8px;
}

/* -- React Tabs -- */

.big-tabs {
  font-size: 24px;
}

.big-tabs .react-tabs__tab--selected {
  font-weight: 600;
}

.small-tabs {
  font-size: 16px;
}

/* -- Scrollable tabs -- */

.tab-container {
  overflow-x: scroll;
}

.tab-container .react-tabs__tab-list {
  width: max-content;
  border: none;
  margin: 0;
  border-bottom: 1px solid #aaa;
}

.tab-container .react-tabs__tab {
  padding-bottom: 15px;
}

@keyframes flash-highlight {
  from {background-color: var(--base-color, #20c7d4);}
  to {background-color: #eee;}
}

.flash-highlight {
  animation: flash-highlight 8s;
}

.event-data {
  padding: 10px;
  margin-top: -30px;
}

.red-text {
  color: #dd3e31;
}

.green-text {
  color: #1AC267;
}

.grey-text {
  color: #aaa;
}

.orange-text {
  color: #ff8819;
}

.subtable {
  text-align: right !important;
  background-color: #c5f9fd !important;
}

.jump-to-date-tooltip-container {
  position: relative;
  display: inline-block;
}

.jump-to-date-tooltip {
  position: absolute;
  z-index: 1;
  background-color: #222;
  color: #fff;
  padding: 5px 0;
  border-radius: 6px;
  text-align: center;
  width: 240px;
  bottom: 100%;
  left: 50%;
  margin-left: -120px;
  opacity: 0.9;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-container:hover .tooltip {
  opacity: 0.9;
}

.tooltip {
  position: absolute;
  z-index: 1;
  background-color: #222;
  color: #fff;
  padding: 10px;
  border-radius: 6px;
  text-align: center;
  width: 240px;
  left: 50%;
  margin-left: -120px;
  opacity: 0;
}

.tooltip::before {
  content: '';
  position: absolute;
  left: 50%;
  margin-left: -19px;
  border-style: solid;
}

.tooltip.above {
  bottom: 130%;
}

.tooltip.above::before {
  bottom: -15px;
  border-width: 15px 19px 0 19px;
  border-color: #222 transparent transparent transparent;
}

.tooltip.below {
  top: 130%;
}

.tooltip.below::before {
  top: -15px;
  border-width: 0 15px 15px 15px;
  border-color: transparent transparent #222 transparent;
}


/* -- Loading Spinner --*/
.lds-ring {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 22px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 26px;
  height: 26px;
  margin-left: 8px;
  border: 6px solid var(--base-color, #20c7d4);
  border-radius: 50%;
  animation: lds-ring 1.4s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--base-color, #20c7d4) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.th-helptext {
  margin-top: -35px;
  padding-bottom: 35px;
  font-weight: normal !important;
}

.tr-helptext {
  margin-top: -25px;
  padding-bottom: 25px;
}

.visible-link {
  text-decoration-line: revert !important;
}

.visible-link:hover {
  background-color: var(--base-color, #20c7d4) !important;
  text-decoration-line: underline !important;
  cursor: pointer;
}

.event-row {
  background-color: #e0e0e0;
  border-radius: 10px;
  padding: 10px;
  padding-left: 15px;
  display: grid;
  grid-template-columns: auto auto auto;
}

.event-panel.button {
  float:  right;
  margin-right: 10px;
  margin-top: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
}

/* --- Suggested Merges --- */
.suggested-merge {
  background-color: #e0e0e0;
  border-radius: 10px;
  padding: 10px;
  padding-left: 15px;
}

.selected-merge {
  background-color: var(--base-color, #20c7d4);
  border-radius: 10px;
  padding: 10px;
  padding-left: 15px;
}

.selected-merge-details {
  display: grid;
  grid-template-columns: auto auto auto;
}

.margin-auto {
  margin: auto !important;
}

.padded {
  padding: 10px;
}

.bottom-padding {
  padding-bottom: 30px;
}

.small-bottom-padding {
  padding-bottom: 10px;
}

.margin-zero {
  margin: 0;
}

.center-text {
  display: block;
  margin: auto;
  width: max-content;
}

.full-width.error {
  width: 90%;
  margin-left: 10%;
}

.break-time-bg {
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.reason-selection input[type="checkbox"] {
  width: 60px;
  margin-bottom: 6px;
  margin-top: 6px;
}

.reason-container {
  display: flex;
  align-items: center;
  min-width: 50%;
}

/* --- Import Previews --- */

.existing-import-person {
  background-color: yellow;
  width: 5px;
  height: 35px;
  margin-left: -16px;
  margin-top: -10px;
  position: fixed;
}

.new-import-person {
  background-color: green;
  width: 5px;
  height: 35px;
  margin-left: -16px;
  margin-top: -10px;
  position: fixed;
}

.page-button-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 30px;
  margin-bottom: -23px;
}

.left-button-container {
}

.right-button-container {
  text-align: right;
}

.page-info {
  display: inline-block;
  line-height: 70px;
}

.email-checkbox-container {
  float: right;
}

.with-icon {
  margin-left: 10px !important;
}

/* -- Audits -- */

.audit-box {
  background-color: #ccc;
  border-radius: 7px;
  padding: 20px;
  margin: 10px;
}

.audit-box .row {
  margin-bottom: 10px;
}

/* -- Booking Form -- */

.background-image {
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  overflow-x: scroll;
}

.booking-container {
  display: grid;
  place-items: center;
}

.booking-container .error {
  font-size: 36px;
  margin-top: 60px;
  text-align: center;
  float: none;
}

.booking-form {
  width: 1070px;
}

.service-form,
.staff-form,
.confirm-form,
.slot-calendar,
.slot-selection,
.confirmed-form {
  border: 7px solid var(--page-border-color, #20c7d4);
  border-radius: 30px;
  background-color: #ffffffde;
  text-align: center;
}

.booking-form h1,
.booking-form h3 {
  border-radius: 10px;
  background-color: #ffffffde;
  width: fit-content;
  padding: 7px 17px;
}

.booking-form .button:not([disabled]):hover {
  background-color: var(--page-border-color, #20c7d4);
}

.booking-form .selection:hover {
  cursor: pointer;
  background-color: #000;
  color: #fff;
}

.selected-options {
  text-align: right;
}

.booking-progress {
  border: 4px solid color-mix(in srgb, var(--page-border-color, #20c7d4) 77%, white);
  background-color: color-mix(in srgb, var(--page-border-color, #20c7d4) 77%, white);
  border-radius: 30px;
/*  background-color: #ffffffde;*/
  margin-bottom: 40px
}

.progress-section {
  padding: 0 0 0 20px;
  border: 3px solid var(--page-border-color, #20c7d4);
  border-radius: 26px;
  margin-bottom: -3px;
  background-color: #fff;
}

.progress-section:last-child {
  margin-bottom: 0;
}

.small-button {
  margin: 10px;
  border-radius: 10px;
  font-size: 16px;
  border-width: 1px;
}

.small-button:hover {
  border-width: 1px !important;
}

.selection {
  padding: 15px 20px;
  border-bottom: 1px solid #636363;
  font-size: 22px;
  font-weight: 600;
}

.service-form .selection:first-child,
.staff-form .selection:first-child {
  border-top-left-radius: 23px;
  border-top-right-radius: 23px;
}

.service-form .selection:last-child,
.staff-form .selection:last-child {
  border-bottom-left-radius: 23px;
  border-bottom-right-radius: 23px;
}

.slot-form {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.slot-calendar {
  margin-right: 10px;
  padding: 10px;
  height: 350px;
}

.slot-selection {
  margin-left: 10px;
  height: fit-content;
}

.slot-scroller {
  max-height: 550px;
  overflow: scroll;
  border-bottom-right-radius: 23px;
  border-bottom-left-radius: 23px;
}

.slot-calendar .react-calendar {
  border: none;
  background-color: transparent;
  border-radius: 23px;
}

.confirm-form {
  padding: 40px 60px;
}

.confirm-form .text {
  font-size: 22px;
  text-align: left;
}

.confirm-form input {
  width: 98%;
  height: 30px;
  font-size: 22px;
  padding-left: 6px;
  border: 1px solid #555;
  border-radius: 10px;
  margin-bottom: 15px;
}

.confirm-form input:last-of-type {
  margin-bottom: 40px;
}

.confirmed-form {
  padding: 30px;
}

.confirmed-form .booking-progress {
  width: fit-content;
  text-align: center;
  margin: 0 auto;
  font-size: 22px;
}

.confirmed-form .progress-section {
  padding: 13px 50px;
}

.red {
  color: #b80c09;
  font-weight: 600;
}


/* -- collapisble helper -- */

.collapsible-button {
 background-color: #ccc;
 padding: 7px;
 border-radius: 5px;
}

.collapsible-icon {
  float: right;
  margin-left: 5px;
}

.collapsible-button:hover {
 background-color: #777;
 color: #fff;
}

.collapsible-content {
  overflow: hidden;
  animation: fillheight 0.8s 1;
}

@keyframes fillheight {
  from {max-height: 0px;}
  to {max-height: 1000px;}
}

@media only screen and (max-width: 1800px) {
  .legend {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1300px) {
  .legend {
    font-size: 13px;
  }
}

@media only screen and (max-width: 1070px) {
  .booking-form {
    width: 100%;
  }

  .booking-container {
    padding: 0 15px 15px;
  }
}

@media only screen and (max-width: 1100px) {
  .legend-box {
    float: none !important;
    margin-top: 0px;
    width: 100%;
  }

  .seperator {
    margin-top: 70px;
  }
}

/* -- Mobile Devices -- */
@media only screen and (max-width: 650px) {

  .legend {

  }

  .main-logo {
    width:  100%;
  }

  .welcome-to {
    margin-left: 0px;
  }

  .login-box {
    position: relative;
    top: 0px;
    transform: none;
    text-align: left;
    margin: 10px;
  }

  .ReactModal__Content {
    width: 100% !important;
  }

  /* -- booking form -- */

  .slot-form {
    grid-template-columns: 1fr;
  }

  .slot-calendar {
    margin: 0 auto 20px;
  }

  .slot-selection {
    margin: 0;
    margin-bottom: 20px;
  }
}

@media print {
  .no-print {
    display: none !important;
  }

  .print-full-width {
    width: 100% !important;
  }

  .slide-pane__overlay {
    position: absolute !important;
  }

  .slide-pane__header {
    display: none !important;
  }

  .slide-pane__content {
    overflow: initial !important;
    background-color: white;
    padding: 0px !important;
  }
}

@media only screen and (max-width: 1730px) {
  .feedback-preview .radio .smileys label {
    padding: 15px;
    font-size: 27px;
  }

  .smileys label {
    min-width: 50px !important;
  }
}

@media only screen and (max-width: 1425px) {
  .feedback-preview .radio .smileys label {
    padding: 10px;
    font-size: 22px;
  }

  .smileys label {
    min-width: 40px !important;
  }
}

@media only screen and (max-width: 1160px) {
  .feedback-preview .radio .smileys label {
    padding: 8px;
    font-size: 18px;
  }

  .smileys label {
    min-width: 35px !important;
  }

  .feedback-preview .question {
    padding: 10px 10px;
  }
}

.slide-pane__content:focus {
  outline: none;
}
